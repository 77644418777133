import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { onSSR } from '@vue-storefront/core';
import { useB2BUiState } from '~/modules/b2b/composables/useB2BUiState';
import { OcrComponent, useOcr } from '~/modules/b2b/prescription';
import { ZnLoading } from '~/modules/b2b/components';
export default defineComponent({
    name: 'OcrPage',
    components: {
        OcrComponent,
        ZnLoading,
    },
    setup() {
        const { search, content } = useContentful('ocr-page');
        const { loading, toggleLoading } = useB2BUiState();
        const { ocrPrescription, prescription } = useOcr();
        onSSR(async () => {
            await search({
                url: 'ocr-page',
            });
        });
        const ocrProps = computed(() => { var _a, _b; return (_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields.content[0]) === null || _b === void 0 ? void 0 : _b.fields; });
        const pageProps = computed(() => { var _a; return (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields; });
        const onCapture = async (data) => {
            toggleLoading();
            await ocrPrescription(data);
            toggleLoading();
        };
        return {
            ocrProps,
            pageProps,
            content,
            loading,
            prescription,
            onCapture,
        };
    },
    head() {
        var _a;
        return {
            title: (_a = this.pageProps) === null || _a === void 0 ? void 0 : _a.title,
        };
    },
});
